import React, { useState, useEffect } from 'react';
import Sidebar from '../departmenthead_components/Sidebar';
import Header from '../departmenthead_components/Header';
import { Card, Button, Row, Col, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

const DepartmentHeadDashboard = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [metrics, setMetrics] = useState({
    pendingRequests: 0,
    approvedRequests: 0,
    totalDepartmentHeads: 0,
    totalSecurityPersonnel: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || user.role !== 'department_head') {
      navigate('/');
    }
  }, [navigate]);

  const handleCloseAddUserModal = () => setShowAddUserModal(false);
  const handleShowAddUserModal = () => setShowAddUserModal(true);

  const handleAddSecurityPersonnel = () => {
    navigate('/ManageSecurityPersonnel');
  };

  const handleAddDepartmentHead = () => {
    navigate('/ManageDepartmentHead');
  };

  const handleHomeClick = () => {
    navigate('/admin-dashboard');
  };

  const fetchMetrics = async () => {
    const user = JSON.parse(localStorage.getItem('user'));

    // Fetch the department of the logged-in department head
    const { data: departmentHeadData, error: departmentError } = await supabase
      .from('department_head')
      .select('department')
      .eq('id', user.id)
      .single();

    if (departmentError) {
      console.error('Error fetching department:', departmentError);
      return;
    }

    const userDepartment = departmentHeadData.department;
    const today = new Date();
    const todayDateString = today.toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
    
    // Fetch pending requests filtered by the user's department and today's date (using `date_trunc` to match date only)
    const { data: pendingData, error: pendingError } = await supabase
      .from('pass_slip_req')
      .select('*')
      .eq('status', 'Pending')
      .eq('department', userDepartment)
      .filter('date', 'gte', `${todayDateString} 00:00:00`)
      .filter('date', 'lte', `${todayDateString} 23:59:59`); // Use `gte` for start of the day and `lte` for end of the day
    
    // Fetch approved requests filtered by the user's department
    const { data: approvedData, error: approvedError } = await supabase
      .from('pass_slip_req')
      .select('*')
      .eq('status', 'Approved by the Head Office and Admin')
      .eq('department', userDepartment); // Filter by department

    const { data: departmentHeads, error: departmentHeadError } = await supabase
      .from('department_head')
      .select('*');

    const { data: securityPersonnel, error: securityPersonnelError } = await supabase
      .from('security_personnel')
      .select('*');

    if (pendingError || approvedError || departmentHeadError || securityPersonnelError) {
      console.error('Error fetching data', pendingError || approvedError || departmentHeadError || securityPersonnelError);
    } else {
      setMetrics({
        pendingRequests: pendingData.length,
        approvedRequests: approvedData.length,
        totalDepartmentHeads: departmentHeads.length,
        totalSecurityPersonnel: securityPersonnel.length,
      });
    }
  };

  const handlePendingRequestsClick = () => {
    navigate('/DHSliprequest'); // Navigate to the /SlipRequests route
  };

  const handleApprovedRequestsClick = () => {
    navigate('/DHhistory'); // Navigate to the /History route
  };

  useEffect(() => {
    fetchMetrics();
  }, []);

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: '#f4f4f4',
        color: '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar />

      <div style={{ flex: 1 }}>
        <Header onHomeClick={handleHomeClick} />

        <main
          className="main-content"
          style={{
            backgroundColor: '#ffffff',
            padding: '20px',
            borderRadius: '12px',
            margin: '20px',
            color: '#000',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }}
        >
          <h1>Head Office Dashboard</h1>

          <Row>
            <Col md={3} className="mb-4">
              <Card onClick={handlePendingRequestsClick} style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>Pending Employee Requests</Card.Title>
                  <Card.Text>{metrics.pendingRequests}</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} className="mb-4">
              <Card onClick={handleApprovedRequestsClick} style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>Approved Requests</Card.Title>
                  <Card.Text>{metrics.approvedRequests}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </main>

        <Modal show={showAddUserModal} onHide={handleCloseAddUserModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              variant="primary"
              className="w-100 mb-2"
              onClick={handleAddSecurityPersonnel}
            >
              Add Security Personnel
            </Button>
            <Button
              variant="secondary"
              className="w-100"
              onClick={handleAddDepartmentHead}
            >
              Add Department Head
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddUserModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default DepartmentHeadDashboard;
