// Header.js
import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "./EVSU.png"
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Header({ toggleDarkMode, isDarkMode }) {
  const navigate = useNavigate(); 

  const handleHomeClick = () => {
    navigate('/DepartmentHD'); // Navigate to admin-dashboard
  };



  return (
    <Navbar expand="lg" className={isDarkMode ? 'bg-dark navbar-dark' : 'bg-body-tertiary'}>
      <Container fluid>

      <Navbar.Brand href="#">
        <img 
        src={logo} 
        alt="logo" 
        style={{ width: '50px', height: '50px', borderRadius: '8px' }} 
      />
       </Navbar.Brand>
      
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link onClick={handleHomeClick}>Home</Nav.Link>
            <NavLink to="/DHProfile" className="nav-link">Profile</NavLink>
        
           
          </Nav>
      
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
