import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { supabase } from './supabaseClient';
import evsuLogo from './images/EVSU.png'; 

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email === '' || password === '') {
      setError('Both fields are required');
      return;
    }

    try {
      let { data, error } = await supabase
        .from('admin')
        .select('id, email, password, role')
        .eq('email', email)
        .single();

      if (!data) {
        ({ data, error } = await supabase
          .from('department_head')
          .select('id, email, password, role, department')
          .eq('email', email)
          .single());
      }

      if (error || !data || data.password !== password) {
        setError('Invalid email or password');
        return;
      }

    
      const userData = {
        id: data.id,
        email: data.email,
        role: data.role,
      };
      localStorage.setItem('user', JSON.stringify(userData));

      console.log('User data stored in localStorage:', JSON.parse(localStorage.getItem('user')));

      navigate(data.role === 'department_head' ? '/DepartmentHD' : `/${data.role}-dashboard`);
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };


  return (
    <div className="login-background">
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <Row className="w-100">
          <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mb-4 mb-md-0">
            <img src={evsuLogo} alt="EVSU Logo" className="img-fluid" style={{ maxWidth: '300px' }} />
          </Col>
          <Col xs={12} md={6}>
            <Card className="p-4 shadow-lg">
              <h2 className="text-center mb-4">Login</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                {error && <Alert variant="danger">{error}</Alert>}

                <Button variant="primary" type="submit" className="w-100">
                  Login
                </Button>
              </Form>

              <div className="mt-3 text-center">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>

             
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
