import React, { useState, useEffect } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { supabase } from '../supabaseClient';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const Statistics = () => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const { data, error } = await supabase
                    .from('pass_slip_req')
                    .select('employee_id, employee_name, time_in, time_out, destination, purpose, expected_duration, date')
                    .eq('status', 'Approved by the Head Office and Admin');

                if (error) {
                    throw error;
                }

                data.sort((a, b) => a.employee_id - b.employee_id);

                const groupedRequests = data.reduce((acc, request) => {
                    const { employee_id, employee_name, destination, time_in, time_out, purpose, expected_duration, date } = request;
                    if (!acc[employee_id]) {
                        acc[employee_id] = {
                            employee_id,
                            employee_name,
                            total_expected_duration: 0,
                            requests: [],
                            dates: [],
                        };
                    }
                    acc[employee_id].total_expected_duration += parseInt(expected_duration);
                    acc[employee_id].requests.push({ destination, purpose, expected_duration, date, time_in, time_out }); // Add time_in and time_out here
                    acc[employee_id].dates.push(formatDate(date));
                    return acc;
                }, {});

                const allRequests = Object.values(groupedRequests);
                setRequests(allRequests);
                setFilteredRequests(allRequests); // Initialize filtered requests with all requests
            } catch (error) {
                console.error('Error fetching requests:', error);
            } finally {
                setLoading(false);
            }
        };


        fetchRequests();
    }, []);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        return date.toLocaleString();
    };

    const convertDuration = (minutes) => {
        const hrs = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hrs} hrs ${mins} min`;
    };

    const calculateTotalHoursSpent = (time_in, time_out) => {
        if (!time_in || !time_out) return 'N/A';
        const timeInDate = new Date(time_in);
        const timeOutDate = new Date(time_out);
        const diff = (timeOutDate - timeInDate) / (1000 * 60 * 60); // difference in hours
        const hours = Math.floor(diff);
        const minutes = Math.round((diff - hours) * 60); // Convert remaining fraction of hour to minutes
        return `${hours} hr ${minutes} min`;
    };



    const handleFilter = () => {
        const filtered = requests.filter(request => {
            if (request.requests.length > 0) { // Check if requests array has elements
                const requestDate = new Date(request.requests[0].date);
                return requestDate >= new Date(fromDate) && requestDate <= new Date(toDate);
            }
            return false;
        });
        setFilteredRequests(filtered);
    };

    // Helper function to format time in 24-hour format without converting to local time
    function formatTime24Hrs(timestamp) {
        if (!timestamp) return 'N/A'; // Return 'N/A' if timestamp is null or empty

        const date = new Date(timestamp);
        const options = { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'UTC' };

        return date.toLocaleTimeString('en-US', options); // 24-hour format in UTC
    }



    // Function to export PDF
    const exportPDF = () => {
        const input = document.getElementById('table-to-pdf'); // Get the element you want to convert to PDF
        html2canvas(input, { scale: 2 }).then((canvas) => { // Increase scale for better quality
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'pt', 'a4'); // Set to portrait orientation
            const imgWidth = pdf.internal.pageSize.getWidth(); // Use full page width
            const pageHeight = pdf.internal.pageSize.getHeight(); // A4 height in pt
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Adjust the height if it exceeds page height
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('approved_slip_requests.pdf'); // Save the PDF with a specified name
        });
    };

    return (
        <div style={styles.container}>
            <div style={styles.sidebar}>
                <Sidebar />
            </div>

            <div style={styles.mainContent}>
                <Header />

                <div style={styles.content}>
                    <h2 className="mb-4">Approved Slip Requests Statistics</h2>
                    <Form className="mb-4 d-flex align-items-center">
                        <Form.Group className="mr-4">
                            <Form.Label className="mr-2">From:</Form.Label>
                            <Form.Control
                                type="date"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                                style={{ width: '120px' }}
                                className="mr-3"
                            />
                        </Form.Group>
                        <Form.Group className="mr-4">
                            <Form.Label className="mr-2">To:</Form.Label>
                            <Form.Control
                                type="date"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                                style={{ marginLeft: '10px' }}
                            />
                        </Form.Group>

                        <Button onClick={handleFilter} style={{ marginLeft: '15px', marginTop: '40px' }}>Filter</Button>
                        <Button
                            onClick={exportPDF}
                            style={{
                                marginLeft: '15px',
                                marginTop: '40px',
                                backgroundColor: 'red', // Set background color to red
                                color: 'white', // Set text color to white for contrast
                            }}
                        >
                            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                            Export PDF
                        </Button>   </Form>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div id="table-to-pdf"> {/* Add this div to wrap your table */}
                            <Table striped bordered hover responsive className="text-center" style={{ width: '100%' }}>
                                <thead className="table-dark">
                                    <tr>
                                        <th>Employee ID</th>
                                        <th>Employee Name</th>
                                        <th>Destination</th>
                                        <th>Purpose</th>
                                        <th>Expected Duration</th>
                                        <th>Time In</th>
                                        <th>Time Out</th>
                                        <th>Total Hrs Spent</th> {/* New column */}
                                        <th>Date</th>
                                        <th>Total Hrs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredRequests.length > 0 ? (
                                        // Sort requests by the 'dates' field in descending order (latest date first)
                                        filteredRequests.sort((a, b) => {
                                            const dateA = new Date(a.dates[0]);
                                            const dateB = new Date(b.dates[0]);
                                            return dateB - dateA; // Sorting in descending order (latest date first)
                                        }).map((request, index) => (
                                            <React.Fragment key={request.employee_id}>
                                                <tr>
                                                    {index === 0 || filteredRequests[index - 1].employee_id !== request.employee_id ? (
                                                        <>
                                                            <td rowSpan={request.requests.length} style={styles.cellBorder}>{request.employee_id}</td>
                                                            <td rowSpan={request.requests.length} style={styles.cellBorder}>{request.employee_name}</td>
                                                        </>
                                                    ) : null}
                                                    <td style={styles.cellBorder}>{request.requests[0].destination}</td>
                                                    <td style={styles.cellBorder}>{request.requests[0].purpose}</td>
                                                    <td style={styles.cellBorder}>{convertDuration(request.requests[0].expected_duration)}</td>
                                                    <td style={styles.cellBorder}>{formatTime24Hrs(request.requests[0].time_in)}</td>
                                                    <td style={styles.cellBorder}>{formatTime24Hrs(request.requests[0].time_out)}</td>
                                                    <td style={styles.cellBorder}>{calculateTotalHoursSpent(request.requests[0].time_out, request.requests[0].time_in)}</td> {/* Total Hrs Spent */}
                                                    <td style={styles.cellBorder}>{request.dates[0]}</td>
                                                    <td rowSpan={request.requests.length} style={styles.cellBorder}>{convertDuration(request.total_expected_duration)}</td>
                                                </tr>
                                                {request.requests.slice(1).map((req, subIndex) => (
                                                    <tr key={subIndex}>
                                                        <td style={styles.cellBorder}>{req.destination}</td>
                                                        <td style={styles.cellBorder}>{req.purpose}</td>
                                                        <td style={styles.cellBorder}>{convertDuration(req.expected_duration)}</td>
                                                        <td style={styles.cellBorder}>{formatTime24Hrs(req.time_in)}</td>
                                                        <td style={styles.cellBorder}>{formatTime24Hrs(req.time_out)}</td>
                                                        <td style={styles.cellBorder}>{calculateTotalHoursSpent(req.time_out, req.time_in)}</td> {/* Total Hrs Spent */}
                                                        <td style={styles.cellBorder}>{request.dates[subIndex + 1]}</td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="10" className="text-center">No approved requests found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>


                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        height: '100vh',
    },
    sidebar: {
        width: '250px', // Adjust the width of the sidebar as needed
        backgroundColor: '#f8f9fa', // Background color for sidebar
    },
    mainContent: {
        marginLeft: 15,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flexGrow: 1,
        overflowY: 'auto', // Enable vertical scrolling
        padding: '20px', // Padding around the content
    },
    cellBorder: {
        border: '3px solid black', // Light gray border for cells
    },
};

export default Statistics;
