import React, { useState } from 'react';
import { Container, Form, Button, Alert, Card } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';

const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email; // Get the email passed from OTPVerification
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    // Determine which table to update based on the email
    const { data: adminData, error: adminError } = await supabase
      .from('admin')
      .select('*')
      .eq('email', email)
      .single();

    const { data: deptHeadData, error: deptHeadError } = await supabase
      .from('department_head')
      .select('*')
      .eq('email', email)
      .single();

    if (adminError && deptHeadError) {
      setError('Email not found in our records.');
      return;
    }

    let updateError;
    if (adminData) {
      // Update password in admin table
      ({ error: updateError } = await supabase
        .from('admin')
        .update({ password: newPassword })
        .eq('email', email));
    } else if (deptHeadData) {
      // Update password in department_head table
      ({ error: updateError } = await supabase
        .from('department_head')
        .update({ password: newPassword })
        .eq('email', email));
    }

    if (updateError) {
      setError('Failed to change password. Please try again.');
      return;
    }

    setSuccess('Password changed successfully!');
    setTimeout(() => {
      navigate('/'); // Redirect to login page after success
    }, 2000);
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Card className="shadow-lg p-4" style={{ maxWidth: '400px', width: '100%' }}>
        <Card.Header className="text-center bg-primary text-white">
          <h4>Change Password</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleChangePassword}>
            <Form.Group className="mb-4" controlId="formBasicNewPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                isInvalid={!!error}
              />
              <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm your new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                isInvalid={!!error}
              />
              <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </Form.Group>

            {success && <Alert variant="success">{success}</Alert>}

            <Button variant="primary" type="submit" className="w-100">
              Change Password
            </Button>
          </Form>
        </Card.Body>
        <Card.Footer className="text-center">
          <small className="text-muted">Enter your new password.</small>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default ChangePassword;
