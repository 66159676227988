import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { supabase } from '../supabaseClient'; // Import supabase client
import { Table, Button, Form, Modal } from 'react-bootstrap';
import Sidebar from '../admin_components/Sidebar'; // Import Sidebar component
import Header from '../admin_components/Header'; // Import Header component

const ManageSec = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [personnel, setPersonnel] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [newPersonnel, setNewPersonnel] = useState({
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    phone: '',
  });

  const navigate = useNavigate(); // Initialize useNavigate hook

  // Function to fetch personnel data
  const fetchAndSetPersonnel = async () => {
    console.log('Fetching personnel data...');
    try {
      const { data, error } = await supabase.from('security_personnel').select('*');
      if (error) {
        console.error('Error fetching personnel:', error);
        return;
      }
      console.log('Fetched data:', data);
      if (Array.isArray(data)) {
        setPersonnel(data);
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching personnel:', error);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    // If no user data or the user is not an admin, redirect to login
    if (!user || user.role !== 'admin') {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    fetchAndSetPersonnel();

    // Polling every 5 seconds
    const intervalId = setInterval(() => {
      fetchAndSetPersonnel();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const addPersonnel = async () => {
    // Validate phone number
    if (!/^\d+$/.test(newPersonnel.phone)) {
      alert('Phone number must contain only numbers.');
      return;
    }

    try {
      console.log('Adding new personnel:', newPersonnel);
      const { data, error } = await supabase.from('security_personnel').insert([newPersonnel]);
      if (error) {
        console.error('Error adding personnel:', error);
        return;
      }
      console.log('Added personnel data:', data);
      if (Array.isArray(data)) {
        // Fetch and update the personnel list after adding
        await fetchAndSetPersonnel();
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error adding personnel:', error);
    } finally {
      setShowModal(false);
      setNewPersonnel({
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        phone: '',
      });
    }
  };

  const deletePersonnel = async () => {
    if (!selectedPerson) return;

    try {
      console.log('Deleting person:', selectedPerson);
      const { error } = await supabase.from('security_personnel').delete().eq('id', selectedPerson.id);
      if (error) {
        console.error('Error deleting personnel:', error);
        return;
      }
      // Refresh the personnel list after deletion
      await fetchAndSetPersonnel();
    } catch (error) {
      console.error('Error deleting personnel:', error);
    } finally {
      setShowDeleteModal(false);
      setSelectedPerson(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input changed: ${name} = ${value}`);
    setNewPersonnel(prev => ({ ...prev, [name]: value }));
  };

  const handlePhoneInput = (e) => {
    const value = e.target.value;
    // Only keep numeric values
    const numericValue = value.replace(/\D/g, '');
    setNewPersonnel(prev => ({ ...prev, phone: numericValue }));
  };

  const handleViewInfo = (person) => {
    console.log('Viewing info for:', person);
    navigate(`/ViewPersonnelInfo?id=${person.id}`); // Navigate to view info page with query parameter
  };

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: isDarkMode ? '#333' : 'lightgrey',
        color: isDarkMode ? '#f0f0f0' : '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar /> {/* Sidebar Component */}

      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} /> {/* Header Component */}

        <main
          className="main-content"
          style={{
            backgroundColor: isDarkMode ? '#444' : 'white',
            padding: '20px',
            borderRadius: '8px',
            margin: '20px',
            color: isDarkMode ? '#f0f0f0' : '#000',
          }}
        >
          <h1>Manage Security Personnel</h1>
          <br/>
          <div>
            <Button variant="primary" onClick={() => setShowModal(true)}>
              Add New Personnel
            </Button>
          </div>
          <br/>
          
  
          <div className="table-responsive">
            <Table striped bordered hover variant={isDarkMode ? 'dark' : 'light'}>
              <thead>
                <tr>
                 
                  <th style={{ textAlign: 'center' }}>Email</th>
                  <th style={{ textAlign: 'center' }}>First Name</th>
                  <th style={{ textAlign: 'center' }}>Last Name</th>
                  <th style={{ textAlign: 'center' }}>Phone</th>
                  <th style={{ textAlign: 'center' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {personnel.length === 0 ? (
                  <tr>
                    <td colSpan="6" style={{ textAlign: 'center' }}>No personnel found</td>
                  </tr>
                ) : (
                  personnel.map((person) => (
                    <tr key={person.id}>
                 
                      <td style={{ textAlign: 'center' }}>{person.email}</td>
                      <td style={{ textAlign: 'center' }}>{person.firstname}</td>
                      <td style={{ textAlign: 'center' }}>{person.lastname}</td>
                      <td style={{ textAlign: 'center' }}>{person.phone}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          variant="info"
                          size="sm"
                          onClick={() => handleViewInfo(person)}
                        >
                          View Info
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          className="ml-2"
                          onClick={() => {
                            setSelectedPerson(person);
                            setShowDeleteModal(true);
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>

          {/* Modal for adding new personnel */}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Personnel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={newPersonnel.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={newPersonnel.password}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={newPersonnel.firstname}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={newPersonnel.lastname}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={newPersonnel.phone}
                    onChange={handlePhoneInput}
                  />
                </Form.Group>
                <Button variant="primary" onClick={addPersonnel}>
                  Add Personnel
                </Button>
              </Form>
            </Modal.Body>
          </Modal>

          {/* Modal for deleting personnel */}
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this personnel?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </Button>
              <Button variant="danger" onClick={deletePersonnel}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default ManageSec;
