import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../departmenthead_components/Sidebar';
import Header from '../departmenthead_components/Header';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

const DHhistory = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [requests, setRequests] = useState([]);
  const [department, setDepartment] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchDepartment();
  }, []);

  const fetchDepartment = async () => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user || user.role !== 'department_head') {
      navigate('/');
      return;
    }

    const { data, error } = await supabase
      .from('department_head')
      .select('department')
      .eq('id', user.id)
      .single();

    if (error) {
      console.error('Error fetching department:', error);
    } else {
      setDepartment(data.department);
      fetchRequests(data.department);
    }
  };

  // Fetch requests from Supabase based on department
  const fetchRequests = async (department) => {
    const { data, error } = await supabase
      .from('pass_slip_req')
      .select('*') // Ensure this selects the signatory field as well
      .eq('status', 'Approved by the Head Office and Admin')
      .eq('department', department); // Ensure this filters by department

    if (error) {
      console.error('Error fetching requests:', error);
    } else {
      setRequests(data);
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleShowDetailModal = (request) => {
    setSelectedRequest(request);
    setShowDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
    setSelectedRequest(null);
  };

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: isDarkMode ? '#333' : 'lightgrey',
        color: isDarkMode ? '#f0f0f0' : '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar />

      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

        <main
          className="main-content"
          style={{
            backgroundColor: isDarkMode ? '#444' : 'white',
            padding: '20px',
            borderRadius: '8px',
            margin: '20px',
            color: isDarkMode ? '#f0f0f0' : '#000',
          }}
        >
          <h1>Pass Slip Request History</h1>
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <Table
  striped
  bordered
  hover
  variant={isDarkMode ? 'dark' : 'light'}
  style={{
    backgroundColor: isDarkMode ? '#555' : '#fff',
    color: isDarkMode ? '#f0f0f0' : '#000',
  }}
>
  <thead>
    <tr>
      <th>Purpose</th>
      <th>Employee Name</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {requests
      .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
      .map(request => (
        <tr
          key={request.id}
          style={{
            backgroundColor:
              request.status === 'Approved by the Head Office and Admin' ? '#d4edda' : '#f8d7da',
            color:
              request.status === 'Approved by the Head Office and Admin' ? '#155724' : '#721c24',
          }}
        >
          <td>{request.purpose}</td>
          <td>{request.employee_name}</td>
          <td>{request.status}</td>
          <td>
            <Button
              variant="info"
              size="sm"
              onClick={() => handleShowDetailModal(request)}
            >
              View Details
            </Button>
          </td>
        </tr>
      ))}
  </tbody>
</Table>

          </div>

          {/* Detail Modal */}
          <Modal
            show={showDetailModal}
            onHide={handleCloseDetailModal}
            centered
          >
            <Modal.Header closeButton style={{ backgroundColor: isDarkMode ? '#444' : '#f1f1f1' }}>
              <Modal.Title style={{ color: isDarkMode ? '#f0f0f0' : '#000' }}>Request Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: isDarkMode ? '#333' : '#fff', color: isDarkMode ? '#f0f0f0' : '#000' }}>
              {selectedRequest && (
                <div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Purpose:</h5>
                    <p>{selectedRequest.purpose}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Employee Name:</h5>
                    <p>{selectedRequest.employee_name}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Status:</h5>
                    <p>{selectedRequest.status}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Employee Email:</h5>
                    <p>{selectedRequest.employee_email}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Department:</h5>
                    <p>{selectedRequest.department}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Phone Number:</h5>
                    <p>{selectedRequest.phone_number}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Destination:</h5>
                    <p>{selectedRequest.destination}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Date:</h5>
                    <p>{new Date(selectedRequest.date).toLocaleString()}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Expected Duration:</h5>
                    <p>{new Date(selectedRequest.expected_duration).toLocaleString()}</p>
                  </div>
                  <div style={{ marginBottom: '15px', textAlign: 'center' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Proof:</h5>
                    {selectedRequest.proof_url ? (
                      <img
                        src={selectedRequest.proof_url}
                        alt="Proof"
                        style={{
                          maxWidth: '100%', // Responsive width
                          maxHeight: '300px', // Limit height
                          borderRadius: '8px', // Rounded corners
                          border: `2px solid ${isDarkMode ? '#f0f0f0' : '#000'}`, // Border color based on mode
                          display: 'block', // Centering effect
                          margin: '0 auto', // Centering effect
                        }}
                      />
                    ) : (
                      <p>No proof available</p>
                    )}
                  </div>
                  {selectedRequest.signatory && (
                    <div style={{ marginTop: '15px', textAlign: 'center' }}> {/* Center the signatory text */}
                      <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Signatory:</h5>
                      <p>{selectedRequest.signatory}</p>
                    </div>
                  )}

                </div>
              )}
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: isDarkMode ? '#444' : '#f1f1f1' }}>
              <Button variant="secondary" onClick={handleCloseDetailModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default DHhistory;
