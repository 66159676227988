import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa'; // Importing search icon from react-icons
import Button from 'react-bootstrap/Button'; // Importing Button from react-bootstrap
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Header.css';
import logo from "./EVSU.png";
import { NavLink } from 'react-router-dom';

function Header({ toggleDarkMode, isDarkMode }) {
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const navigate = useNavigate(); // Hook for navigation

  const handleHomeClick = () => {
    navigate('/admin-dashboard'); // Navigate to admin-dashboard
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value); // Update the search input state as user types
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Redirect to a search results page or perform search operation
    if (searchQuery.trim()) {
      // Navigate to a search page, passing the query as a URL parameter
      navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <Navbar expand="lg" className={isDarkMode ? 'bg-dark navbar-dark' : 'bg-body-tertiary'}>
      <Container fluid>
        <Navbar.Brand href="#">
          <img
            src={logo}
            alt="logo"
            style={{ width: '50px', height: '50px', borderRadius: '8px' }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link onClick={handleHomeClick}>Home</Nav.Link>
            <NavLink to="/AdminProfile" className="nav-link">Profile</NavLink>
           
          </Nav>

          <Form className="d-flex align-items-center" onSubmit={handleSearchSubmit}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchQuery} // Bind input value to state
              onChange={handleSearchInputChange} // Update state on input change
              style={{ borderRadius: '20px', borderColor: isDarkMode ? '#f0f0f0' : '#000' }} // Rounded corners for search input
            />
            <Button
              variant="outline-success"
              type="submit"
              style={{
                borderRadius: '20px', // Match the input style
                borderColor: isDarkMode ? '#f0f0f0' : '#000',
                background: isDarkMode ? '#444' : '#fff', // Background color for better visibility
                padding: '0.5rem 1rem' // Add horizontal padding
              }}
            >
              <FaSearch style={{ fontSize: '1.5rem', color: isDarkMode ? '#f0f0f0' : '#000' }} />
            </Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
