import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../admin_components/Sidebar'; // Import Sidebar component
import Header from '../admin_components/Header'; // Import Header component

const Reports = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    // If no user data or the user is not an admin, redirect to login
    if (!user || user.role !== 'admin') {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: isDarkMode ? '#333' : 'lightgrey',
        color: isDarkMode ? '#f0f0f0' : '#000', // Text color based on mode
        minHeight: '100vh',
      }}
    >
      <Sidebar /> {/* Sidebar Component */}

      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} /> {/* Header Component */}

        <main
          className="main-content"
          style={{
            backgroundColor: isDarkMode ? '#444' : 'white',
            padding: '20px',
            borderRadius: '8px',
            margin: '20px',
            color: isDarkMode ? '#f0f0f0' : '#000', // Text color for main content
          }}
        >
          <h1>Reports Page</h1>
          {/* Add the rest of your reports page content here */}
        </main>
      </div>
    </div>
  );
};

export default Reports;
