import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { supabase } from '../supabaseClient';

const Manage = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showSecurityModal, setShowSecurityModal] = useState(false);
  const [showStaffModal, setShowStaffModal] = useState(false);
  const [showDepartmentHeadModal, setShowDepartmentHeadModal] = useState(false);

  // Security Personnel State
  const [secName, setSecName] = useState('');
  const [secId, setSecId] = useState('');
  const [secContact, setSecContact] = useState('');
  const [secRoles, setSecRoles] = useState('');
  const [secResponsibilities, setSecResponsibilities] = useState('');

  // Staff Member State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState(''); // New state for role

  // Department Head State
  const [deptHeadFirstName, setDeptHeadFirstName] = useState('');
  const [deptHeadLastName, setDeptHeadLastName] = useState('');
  const [deptHeadEmail, setDeptHeadEmail] = useState('');
  const [deptHeadPassword, setDeptHeadPassword] = useState('');
  const [deptHeadDepartment, setDeptHeadDepartment] = useState('');
  const [deptHeadRole, setDeptHeadRole] = useState(''); // New state for role

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  

  const handleShowSecurityModal = () => setShowSecurityModal(true);
  const handleCloseSecurityModal = () => {
    setShowSecurityModal(false);
    setSecName('');
    setSecId('');
    setSecContact('');
    setSecRoles('');
    setSecResponsibilities('');
  };

  const handleShowStaffModal = () => setShowStaffModal(true);
  const handleCloseStaffModal = () => {
    setShowStaffModal(false);
    setEmail('');
    setPassword('');
    setFirstName('');
    setLastName('');
    setRole(''); // Reset role
  };

  const handleShowDepartmentHeadModal = () => setShowDepartmentHeadModal(true);
  const handleCloseDepartmentHeadModal = () => {
    setShowDepartmentHeadModal(false);
    setDeptHeadFirstName('');
    setDeptHeadLastName('');
    setDeptHeadEmail('');
    setDeptHeadPassword('');
    setDeptHeadDepartment('');
    setDeptHeadRole(''); // Reset role
  };

  const handleAddSecurityPersonnel = async (e) => {
    e.preventDefault();
    // Add logic to save the new security personnel information
    console.log({ secName, secId, secContact, secRoles, secResponsibilities });
    handleCloseSecurityModal(); // Close modal after saving
  };

  const handleAddStaffMember = async (e) => {
    e.preventDefault();
    // Insert new staff member into Supabase with role
    const { error } = await supabase
      .from('staff')
      .insert([
        {
          email: email,
          password: password,
          firstname: firstName,
          lastname: lastName,
          role: role, // Add role to database
        },
      ]);

    if (error) {
      console.error('Error adding staff member:', error.message);
    } else {
      console.log('Staff member added successfully');
    }

    handleCloseStaffModal(); // Close modal after saving
  };

  const handleAddDepartmentHead = async (e) => {
    e.preventDefault();
    // Insert new department head into Supabase with role
    const { error } = await supabase
      .from('department_head')
      .insert([
        {
          email: deptHeadEmail,
          password: deptHeadPassword,
          firstname: deptHeadFirstName,
          lastname: deptHeadLastName,
          department: deptHeadDepartment,
          role: deptHeadRole, // Add role to database
        },
      ]);

    if (error) {
      console.error('Error adding department head:', error.message);
    } else {
      console.log('Department head added successfully');
    }

    handleCloseDepartmentHeadModal(); // Close modal after saving
  };

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: isDarkMode ? '#333' : 'lightgrey',
        color: isDarkMode ? '#f0f0f0' : '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar /> {/* Sidebar Component */}

      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

        <main
          className="main-content"
          style={{
            backgroundColor: isDarkMode ? '#444' : 'white',
            padding: '20px',
            borderRadius: '8px',
            margin: '20px',
            color: isDarkMode ? '#f0f0f0' : '#000',
          }}
        >
          <h1>Manage Personnel</h1>
          <Button variant="primary" onClick={handleShowSecurityModal} className="me-2">
            Add Security Personnel
          </Button>
          <Button variant="secondary" onClick={handleShowStaffModal} className="me-2">
            Add Staff Member
          </Button>
          <Button variant="success" onClick={handleShowDepartmentHeadModal}>
            Add Department Head
          </Button>

          {/* Modal for Adding Security Personnel */}
          <Modal show={showSecurityModal} onHide={handleCloseSecurityModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add Security Personnel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleAddSecurityPersonnel}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    value={secName}
                    onChange={(e) => setSecName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter ID"
                    value={secId}
                    onChange={(e) => setSecId(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Contact Information</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter contact information"
                    value={secContact}
                    onChange={(e) => setSecContact(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select"
                    value={secRoles}
                    onChange={(e) => setSecRoles(e.target.value)}
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="level1">Employee</option>
                    <option value="level2">Department Head</option>
                    <option value="level3">Admin</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Responsibilities</Form.Label>
                  <Form.Control
                    as="select"
                    value={secResponsibilities}
                    onChange={(e) => setSecResponsibilities(e.target.value)}
                    required
                  >
                    <option value="">Select Responsibility</option>
                    <option value="verify">Verify Pass Slip Requests</option>
                    <option value="identify">Identify Pass Slip Requests</option>
                    <option value="administer">Administer Pass Slip System</option>
                  </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit">
                  Add Personnel
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseSecurityModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal for Adding New Staff Member */}
          <Modal show={showStaffModal} onHide={handleCloseStaffModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Staff Member</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleAddStaffMember}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="level1">Employee</option>
                    <option value="level2">Department Head</option>
                    <option value="level3">Admin</option>
                  </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit">
                  Add Member
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseStaffModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal for Adding Department Head */}
          <Modal show={showDepartmentHeadModal} onHide={handleCloseDepartmentHeadModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Department Head</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleAddDepartmentHead}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    value={deptHeadFirstName}
                    onChange={(e) => setDeptHeadFirstName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    value={deptHeadLastName}
                    onChange={(e) => setDeptHeadLastName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={deptHeadEmail}
                    onChange={(e) => setDeptHeadEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={deptHeadPassword}
                    onChange={(e) => setDeptHeadPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Department</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter department"
                    value={deptHeadDepartment}
                    onChange={(e) => setDeptHeadDepartment(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select"
                    value={deptHeadRole}
                    onChange={(e) => setDeptHeadRole(e.target.value)}
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="level1">Employee</option>
                    <option value="level2">Department Head</option>
                    <option value="level3">Admin</option>
                  </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit">
                  Add Department Head
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDepartmentHeadModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default Manage;
