import React, { useState, useEffect } from 'react';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { Card, Button, Row, Col, Container, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

const ManualSlipInput = () => {
    const [slipData, setSlipData] = useState({
        destination: '',
        purpose: '',
        expected_duration: 0, // Store as total minutes
        employee_name: '',
        employee_email: '',
        department: '',
        time_in: '', // Store the time_in input
        time_out: '', // Store the time_out input
        phone_number: '',
        date: '', // This will store the date input
        status: 'Approved by the Head Office and Admin',
        employee_id: '', // Initialize employee_id
        signatory: 'None',  // Set signatory to 'None'
        proof_url: 'None',  // Set proof_url to 'None'
        request_number: 'None' // Set request_number to 'None'
    });

    const [submitted, setSubmitted] = useState(false);
    const [duration, setDuration] = useState({ hours: '', minutes: '' });

    const navigate = useNavigate();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || user.role !== 'admin') {
            navigate('/');
        } else {

        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSlipData({ ...slipData, [name]: value });
    };

    const handleDurationChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'hours' && value < 0) || (name === 'minutes' && value < 0)) {
            return;
        }
        setDuration({ ...duration, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Convert expected duration to total minutes
        const totalMinutes = (parseInt(duration.hours) || 0) * 60 + (parseInt(duration.minutes) || 0);

        // Get the current time
        const currentTime = new Date();
        const date = new Date(slipData.date);  // Get the selected date

        // Combine the selected date with the time_in and time_out to create valid timestamps
        const timeIn = new Date(`${date.toISOString().split('T')[0]}T${slipData.time_in}:00Z`); // Combine date with time_in
        const timeOut = new Date(`${date.toISOString().split('T')[0]}T${slipData.time_out}:00Z`); // Combine date with time_out

        // Set the combined date for the `date` field (using the selected time_in)
        const combinedDate = new Date(`${date.toISOString().split('T')[0]}T${slipData.time_in}:00Z`);

        const finalSlipData = {
            ...slipData,
            time_in: timeIn.toISOString(),  // Store time_in with timestamp
            time_out: timeOut.toISOString(),  // Store time_out with timestamp
            expected_duration: totalMinutes, // Store total minutes
            date: combinedDate.toISOString() // Store date with the selected time
        };

        try {
            const { data, error } = await supabase.from('pass_slip_req').insert([finalSlipData]);
            if (error) throw error;
            console.log('Slip added:', data);

            // Clear the form fields after successful submission
            setSlipData({
                destination: '',
                purpose: '',
                expected_duration: 0, // Reset to 0
                employee_name: '',
                employee_email: '',
                department: '',
                phone_number: '',
                date: '', // Clear the date
                status: 'Approved by the Head Office and Admin',
                employee_id: '',  // Keep the employee_id intact
                signatory: 'None',
                proof_url: 'None',
                request_number: 'None'
            });

            setDuration({ hours: '', minutes: '' }); // Reset duration
            setSubmitted(true);
            setTimeout(() => setSubmitted(false), 3000);
        } catch (error) {
            console.error('Error adding slip:', error.message);
        }
    };


    const inputStyle = {
        borderRadius: '5px',
        height: '50px'
    };

    return (
        <div
            className="dashboard-container"
            style={{
                display: 'flex',
                backgroundColor: '#f4f4f4',
                color: '#000',
                minHeight: '100vh',
            }}
        >
            <Sidebar />

            <div style={{ flex: 1, overflow: 'hidden' }}>
                <Header />
                <Container
                    fluid
                    style={{
                        background: 'rgba(255, 255, 255, 0.15)',
                        padding: '40px',
                        borderRadius: '12px',
                        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',
                        backdropFilter: 'blur(10px)',
                        height: 'calc(100vh - 80px)',
                        overflowY: 'auto',
                    }}
                >
                    <h2
                        className="text-center mb-4"
                        style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                    >
                        Manual Slip Input
                    </h2>
                    <Row className="justify-content-center">
                        <Col md={12}>
                            <Form
                                onSubmit={handleSubmit}
                                style={{
                                    background: 'rgba(255, 255, 255, 0.85)',
                                    borderRadius: '8px',
                                    padding: '20px',
                                }}
                            >
                                <Row>
                                    {/* First Column */}
                                    <Col md={6}>
                                        <Form.Group controlId="date" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="date"
                                                value={slipData.date}
                                                onChange={handleChange}
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="destination" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Destination</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="destination"
                                                value={slipData.destination}
                                                onChange={handleChange}
                                                placeholder="Enter Destination"
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="purpose" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Purpose</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="purpose"
                                                placeholder="Enter Purpose"
                                                value={slipData.purpose}
                                                onChange={handleChange}
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="expected_duration" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Expected Duration</Form.Label>
                                            <Row>
                                                <Col>
                                                    <Form.Control
                                                        type="number"
                                                        name="hours"
                                                        placeholder="Hours"
                                                        value={duration.hours}
                                                        onChange={handleDurationChange}
                                                        style={inputStyle}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        type="number"
                                                        name="minutes"
                                                        placeholder="Minutes"
                                                        value={duration.minutes}
                                                        onChange={handleDurationChange}
                                                        style={inputStyle}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        <Form.Group controlId="time_in" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Time In</Form.Label>
                                            <Form.Control
                                                type="time"
                                                name="time_in"
                                                value={slipData.time_in}
                                                onChange={handleChange}
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="time_out" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Time Out</Form.Label>
                                            <Form.Control
                                                type="time"
                                                name="time_out"
                                                value={slipData.time_out}
                                                onChange={handleChange}
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>


                                    </Col>

                                    <Col md={6}>
                                        <Form.Group controlId="employee_id" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Employee ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="employee_id"
                                                onChange={handleChange}
                                                placeholder="Enter Employee ID"
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="employee_name" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Employee Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="employee_name"
                                                value={slipData.employee_name}
                                                onChange={handleChange}
                                                placeholder="First Name Last Name M.I."
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="employee_email" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Employee Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="employee_email"
                                                placeholder="Enter Email"
                                                value={slipData.employee_email}
                                                onChange={handleChange}
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="department" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Department/Office</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="department"
                                                value={slipData.department}
                                                onChange={handleChange}
                                                required
                                                style={inputStyle}
                                            >
                                                <option value="">Select Department</option> {/* Default empty option */}
                    <option value="Accounting Office">Accounting Office</option>
                    <option value="Administrative and Finance Services">Administrative and Finance Services</option>
                    <option value="Budget Office">Budget Office</option>
                    <option value="Business and Management Department">Business and Management Department</option>
                    <option value="Campus Clinic">Campus Clinic</option>
                    <option value="Cashier and Disbursing Office">Cashier and Disbursing Office</option>
                    <option value="Computer Studies Department">Computer Studies Department</option>
                    <option value="Culture and the Arts">Culture and the Arts</option>
                    <option value="Engineering Department">Engineering Department</option>
                    <option value="Gender and Development Office">Gender and Development Office</option>
                    <option value="Human Resource Management Office">Human Resource Management Office</option>
                    <option value="Institutional Planning and Development Office">Institutional Planning and Development Office</option>
                    <option value="Library">Library</option>
                    <option value="Maintenance and Engineering">Maintenance and Engineering</option>
                    <option value="NSTP Office">NSTP Office</option>
                    <option value="Office of the Campus Director">Office of the Campus Director</option>
                    <option value="Property Inspector">Property Inspector</option>
                    <option value="Quality Assurance Accreditation Center">Quality Assurance Accreditation Center</option>
                    <option value="Registrar Office">Registrar Office</option>
                    <option value="Teacher Education Department">Teacher Education Department</option>
                    <option value="Technology Department">Technology Department</option>
                                            </Form.Control>
                                        </Form.Group>


                                        <Form.Group controlId="phone_number" className="mb-3">
                                            <Form.Label style={{ color: '#A40000' }}>Phone Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="phone_number"
                                                placeholder="Enter Phone Number"
                                                value={slipData.phone_number}
                                                onChange={handleChange}
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <div className="text-center">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        style={{ backgroundColor: '#A40000', border: 'none' }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                            {submitted && (
                                <div className="alert alert-success mt-3" role="alert">
                                    Slip submitted successfully!
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default ManualSlipInput;
