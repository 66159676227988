import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Import supabase client
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import Sidebar from '../admin_components/Sidebar'; // Import Sidebar component
import Header from '../admin_components/Header'; // Import Header component

const DepartmentHeadViewInfo = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [departmentHead, setDepartmentHead] = useState(null);

  // Extract the ID from query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const headId = queryParams.get('id');

  const navigate = useNavigate(); // Hook for navigation

  // Function to fetch specific department head data
  const fetchDepartmentHeadById = async (id) => {
    try {
      const { data, error } = await supabase
        .from('department_head')
        .select('*')
        .eq('id', id)
        .single(); // Fetch a single record by ID
      if (error) {
        console.error('Error fetching department head:', error);
        return;
      }
      setDepartmentHead(data);
    } catch (error) {
      console.error('Error fetching department head:', error);
    }
  };

  useEffect(() => {
    // Check if user data is stored in localStorage
    const user = JSON.parse(localStorage.getItem('user'));

    // If no user data, redirect to login page
    if (!user || user.role !== 'admin') {
      navigate('/');
    }
    if (headId) {
      fetchDepartmentHeadById(headId);
    }
  }, [headId]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleExit = () => {
    navigate('/ManageDepartmentHead'); // Navigate back to ManageDH page
  };

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: isDarkMode ? '#333' : 'lightgrey',
        color: isDarkMode ? '#f0f0f0' : '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar /> {/* Sidebar Component */}

      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} /> {/* Header Component */}

        <main
          className="main-content"
          style={{
            backgroundColor: isDarkMode ? '#444' : 'white',
            padding: '40px', // Increased padding for better spacing
            borderRadius: '8px',
            margin: '20px',
            color: isDarkMode ? '#f0f0f0' : '#000',
            flex: 1, // Ensure the main content takes available space
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6}>
                <h1 className="mb-4">Department Head Profile</h1>
                {departmentHead ? (
                  <Card className={`shadow-sm rounded ${isDarkMode ? 'bg-dark text-light' : 'bg-light'}`}>
                    <Card.Img
                      variant="top"
                      src={departmentHead.profile_picture}
                      alt="Profile Picture"
                      style={{ borderRadius: '50%', width: '200px', height: '200px', objectFit: 'contain', margin: 'auto' }}
                      className="mt-4 mb-3"
                    />
                    <Card.Body>
                      <Card.Title>{departmentHead.firstname} {departmentHead.lastname}</Card.Title>
                      <Card.Text>
                        <strong>Email:</strong> {departmentHead.email}<br />
                        <strong>Phone:</strong> {departmentHead.phone}<br />
                        <strong>Department:</strong> {departmentHead.department}<br />
                       
                      </Card.Text>
                      <Button variant={isDarkMode ? 'outline-light' : 'outline-dark'} className="mt-3" onClick={handleExit}>
                        Back
                      </Button>
                    </Card.Body>
                  </Card>
                ) : (
                  <p>Loading department head data...</p>
                )}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
};

export default DepartmentHeadViewInfo;
