import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Import supabase client
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import Sidebar from '../admin_components/Sidebar'; // Import Sidebar component
import Header from '../admin_components/Header'; // Import Header component

const SecurityPersonnelViewInfo = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [personnel, setPersonnel] = useState(null);

  // Extract the ID from query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const personId = queryParams.get('id');

  const navigate = useNavigate(); // Hook for navigation

  // Function to fetch specific personnel data
  const fetchPersonnelById = async (id) => {
    try {
      const { data, error } = await supabase
        .from('security_personnel')
        .select('*')
        .eq('id', id)
        .single(); // Fetch a single record by ID
      if (error) {
        console.error('Error fetching personnel:', error);
        return;
      }
      setPersonnel(data);
    } catch (error) {
      console.error('Error fetching personnel:', error);
    }
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    // If no user data or the user is not an admin, redirect to login
    if (!user || user.role !== 'admin') {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    if (personId) {
      fetchPersonnelById(personId);
    }
  }, [personId]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleExit = () => {
    navigate('/ManageSecurityPersonnel'); // Navigate back to ManageSec page
  };

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: isDarkMode ? '#333' : 'lightgrey',
        color: isDarkMode ? '#f0f0f0' : '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar /> {/* Sidebar Component */}

      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} /> {/* Header Component */}

        <main
          className="main-content"
          style={{
            backgroundColor: isDarkMode ? '#444' : 'white',
            padding: '40px', // Increased padding for better spacing
            borderRadius: '8px',
            margin: '20px',
            color: isDarkMode ? '#f0f0f0' : '#000',
            flex: 1, // Ensure the main content takes available space
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6}>
                <h1 className="mb-4">Security Personnel Profile</h1>
                {personnel ? (
                  <Card className={`shadow-sm rounded ${isDarkMode ? 'bg-dark text-light' : 'bg-light'}`}>
                    <Card.Img 
                      variant="top" 
                      src={personnel.profile_url} 
                      alt="Profile Picture" 
                      style={{ borderRadius: '50%', width: '200px', height: '200px', objectFit: 'cover', margin: 'auto' }} 
                      className="mt-4 mb-3" 
                    />
                    <Card.Body>
                      <Card.Title>{personnel.firstname} {personnel.lastname}</Card.Title>
                      <Card.Text>
                        <strong>Email:</strong> {personnel.email}<br />
                        <strong>Phone:</strong> {personnel.phone}<br />
                
                      </Card.Text>
                      <Button variant={isDarkMode ? 'outline-light' : 'outline-dark'} className="mt-3" onClick={handleExit}>
                        Back
                      </Button>
                    </Card.Body>
                  </Card>
                ) : (
                  <p>Loading personnel data...</p>
                )}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
};

export default SecurityPersonnelViewInfo;
