import React, { useState, useEffect } from 'react';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { Card, Button, Row, Col, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/AdminDashboard.css';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [metrics, setMetrics] = useState({
    pendingRequests: 0,
    approvedRequests: 0,
    totalDepartmentHeads: 0,
    totalSecurityPersonnel: 0,
  });

  const navigate = useNavigate();

  const handleViewStatistics = () => {
    navigate('/Statistics');
  };

  const handleCloseAddUserModal = () => setShowAddUserModal(false);
  const handleShowAddUserModal = () => setShowAddUserModal(true);

  const handleAddSecurityPersonnel = () => {
    navigate('/ManageSecurityPersonnel');
  };

  const handleAddDepartmentHead = () => {
    navigate('/ManageDepartmentHead');
  };

  const handleHomeClick = () => {
    navigate('/admin-dashboard');
  };

  const handlePendingRequestsClick = () => {
    navigate('/SlipRequests');
  };

  const handleApprovedRequestsClick = () => {
    navigate('/History');
  };

  const handleDepartmentHeadClick = () => {
    navigate('/ManageDepartmentHead');
  };

  const handleSecurityPersonnelClick = () => {
    navigate('/ManageSecurityPersonnel');
  };

  const fetchMetrics = async () => {
    try {
      const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

    // Fetch pending requests approved by the Head Office only, for the current date
    const { data: pendingRequests, count: pendingCount, error: pendingError } = await supabase
      .from('pass_slip_req')
      .select('*', { count: 'exact' })
      .eq('status', 'Approved by the Head Office')
      .filter('created_at', 'eq', currentDate); // Extract only the date for comparison

    if (pendingError) {
      console.error('Error fetching pending requests:', pendingError);
    }

    // Debugging statement to print the fetched pending data
    console.log('Fetched pending requests:', pendingRequests);
    console.log('Pending request count:', pendingCount);
      // Fetch approved requests with full admin approval
      const { data: approvedRequests, count: approvedCount, error: approvedError } = await supabase
        .from('pass_slip_req')
        .select('*', { count: 'exact' })
        .eq('status', 'Approved by the Head Office and Admin');
  
      if (approvedError) {
        console.error('Error fetching approved requests:', approvedError);
      }
  
      // Fetch total department heads
      const { data: departmentHeads, count: departmentHeadCount, error: deptHeadError } = await supabase
        .from('department_head')
        .select('*', { count: 'exact' });
  
      if (deptHeadError) {
        console.error('Error fetching department heads:', deptHeadError);
      }
  
      // Fetch total security personnel
      const { data: securityPersonnel, count: securityPersonnelCount, error: secPersonnelError } = await supabase
        .from('security_personnel')
        .select('*', { count: 'exact' });
  
      if (secPersonnelError) {
        console.error('Error fetching security personnel:', secPersonnelError);
      }
  
      // Set metrics state with results
      setMetrics({
        pendingRequests: pendingCount || 0,
        approvedRequests: approvedCount || 0,
        totalDepartmentHeads: departmentHeadCount || 0,
        totalSecurityPersonnel: securityPersonnelCount || 0,
      });
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };
  
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || user.role !== 'admin') {
      navigate('/');
    }
    fetchMetrics();
    const savedMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedMode);
  }, [navigate]);

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: '#f4f4f4',
        color: '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar />

      <div style={{ flex: 1 }}>
        <Header onHomeClick={handleHomeClick} />

        <main
          className="main-content"
          style={{
            backgroundColor: '#ffffff',
            padding: '20px',
            borderRadius: '12px',
            margin: '20px',
            color: '#000',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }}
        >
          <h1>Admin Dashboard</h1>

          <Row className="mb-4">
            <Col md={3} className="mb-4">
              <Card onClick={handlePendingRequestsClick} style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>Pending Employee Requests</Card.Title>
                  <Card.Text>{metrics.pendingRequests}</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} className="mb-4">
              <Card onClick={handleApprovedRequestsClick} style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>Approved Requests</Card.Title>
                  <Card.Text>{metrics.approvedRequests}</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} className="mb-4">
              <Card onClick={handleDepartmentHeadClick} style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>Total DH/Head Office</Card.Title>
                  <Card.Text>{metrics.totalDepartmentHeads}</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} className="mb-4">
              <Card onClick={handleSecurityPersonnelClick} style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>Total Security Personnel</Card.Title>
                  <Card.Text>{metrics.totalSecurityPersonnel}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title>Shortcuts</Card.Title>
                  <Button
                    variant="primary"
                    className="w-100 mb-2"
                    onClick={handleShowAddUserModal}
                  >
                    Manage Users
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-100"
                    onClick={handleViewStatistics}
                  >
                    View Statistics
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </main>

        <Modal show={showAddUserModal} onHide={handleCloseAddUserModal}>
          <Modal.Header closeButton>
            <Modal.Title>Manage User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              variant="primary"
              className="w-100 mb-2"
              onClick={handleAddSecurityPersonnel}
            >
              Security Personnel
            </Button>
            <Button
              variant="secondary"
              className="w-100"
              onClick={handleAddDepartmentHead}
            >
              Department Head
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddUserModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminDashboard;
