import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Login';
import AdminDashboard from './admin_pages/AdminDashboard';
import SlipRequests from './admin_pages/SlipRequests';
import Manage from './admin_pages/Manage';
import Reports from './admin_pages/Reports';
import History from './admin_pages/History';
import ForgotPassword from './admin_pages/ForgotPassword';
import 'bootstrap/dist/css/bootstrap.min.css';
import ManageSec from './admin_pages/ManageSec';
import ManageDH from './admin_pages/ManageDH';
import ManageEmployee from './admin_pages/ManageEmployee';
import DepartmentHeadDashboard from './department_head_pages/DepartmentHeadDashboard';
import DHSliprequest from './department_head_pages/DHSliprequest';
import DHhistory from './department_head_pages/DHhistory';
import SecurityPersonnelViewInfo from './admin_pages/SecurityPersonnelViewInfo';
import DepartmentHeadViewInfo from './admin_pages/DepartmentHeadViewInfo';
import EmployeeViewInfo from './admin_pages/EmployeeViewInfo';
import AdminProfile from './admin_pages/AdminProfile'; 
import DHProfile from './department_head_pages/DHProfile';
import SearchResult from './admin_pages/SearchResult'; 
import ManualSlipInput from './admin_pages/ManualSlipInput'; 
import OTPVerification from './admin_pages/OTPVerification';
import ChangePassword from './admin_pages/ChangePassword';
import Statistics from './admin_pages/Statistics';
import UserVerification from './admin_pages/UserVerification';

const App = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/SlipRequests" element={<SlipRequests />} />
          <Route path="/Manage" element={<Manage />} />
          <Route path="/Reports" element={<Reports />} />
          <Route path="/History" element={<History />} />
          <Route path="/forgot-password" element={<ForgotPassword />} /> 
          <Route path="/ManageSecurityPersonnel" element={<ManageSec />} /> 
          <Route path="/ManageDepartmentHead" element={<ManageDH />} /> 
          <Route path="/ManageEmployee" element={<ManageEmployee />} /> 
          <Route path="/DepartmentHD" element={<DepartmentHeadDashboard />} /> 
          <Route path="/DHSliprequest" element={<DHSliprequest />} /> 
          <Route path="/DHhistory" element={<DHhistory />} /> 
          <Route path="/ViewPersonnelInfo" element={<SecurityPersonnelViewInfo />} />
          <Route path="/ViewDepartmentHeadInfo" element={<DepartmentHeadViewInfo />} />
          <Route path="/EmployeeViewInfo" element={<EmployeeViewInfo />} /> 
          <Route path="/AdminProfile" element={<AdminProfile />} /> 
          <Route path="/DHProfile" element={<DHProfile />} /> 
          <Route path="/search" element={<SearchResult />} /> 
          <Route path="/ManualSlipInput" element={<ManualSlipInput />} /> 
          <Route path="/otp-verification" element={<OTPVerification />} /> 
          <Route path="/change-password" element={<ChangePassword />} /> 
          <Route path="/Statistics" element={<Statistics />} /> 
          <Route path="/UserVerification" element={<UserVerification />} /> 
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
