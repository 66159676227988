import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../admin_components/Sidebar'; // Import Sidebar component
import Header from '../admin_components/Header'; // Import Header component
import { supabase } from '../supabaseClient'; // Import Supabase client
import Table from 'react-bootstrap/Table'; // Import Bootstrap Table

const SearchResult = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [employeeResults, setEmployeeResults] = useState([]); // State to hold employee results
    const [securityPersonnelResults, setSecurityPersonnelResults] = useState([]); // State to hold security personnel results
    const [departmentHeadResults, setDepartmentHeadResults] = useState([]); // State to hold department head results
    const [loading, setLoading] = useState(true); // State to manage loading state
    const navigate = useNavigate();
    const location = useLocation(); // Hook to access the query parameters

    // Extract search query from URL
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('query');

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        // If no user data or the user is not an admin, redirect to login
        if (!user || user.role !== 'admin') {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                setLoading(true); // Set loading to true before fetching

                // Fetch data from the 'employee' table
                const { data: employeeData, error: employeeError } = await supabase
                    .from('employee')
                    .select('*')
                    .or(`firstname.ilike.%${query}%,lastname.ilike.%${query}%`);

                // Add a table property to the employee results
                const employeeResultsWithTable = employeeData?.map((employee) => ({
                    ...employee,
                    table: 'Employee',
                }));

                // Fetch data from the 'security_personnel' table
                const { data: securityPersonnelData, error: securityPersonnelError } = await supabase
                    .from('security_personnel')
                    .select('*')
                    .or(`firstname.ilike.%${query}%,lastname.ilike.%${query}%`);

                // Add a table property to the security personnel results
                const securityPersonnelResultsWithTable = securityPersonnelData?.map((personnel) => ({
                    ...personnel,
                    table: 'Security Personnel',
                }));

                // Fetch data from the 'department_head' table
                const { data: departmentHeadData, error: departmentHeadError } = await supabase
                    .from('department_head')
                    .select('*')
                    .or(`firstname.ilike.%${query}%,lastname.ilike.%${query}%`);

                // Add a table property to the department head results
                const departmentHeadResultsWithTable = departmentHeadData?.map((head) => ({
                    ...head,
                    table: 'Department Head',
                }));

                // Handle errors
                if (employeeError) {
                    console.error('Error fetching employee data:', employeeError);
                } else {
                    setEmployeeResults(employeeResultsWithTable); // Set employee results with table info
                }

                if (securityPersonnelError) {
                    console.error('Error fetching security personnel data:', securityPersonnelError);
                } else {
                    setSecurityPersonnelResults(securityPersonnelResultsWithTable); // Set security personnel results with table info
                }

                if (departmentHeadError) {
                    console.error('Error fetching department head data:', departmentHeadError);
                } else {
                    setDepartmentHeadResults(departmentHeadResultsWithTable); // Set department head results with table info
                }
            } catch (error) {
                console.error('Unexpected error:', error);
            } finally {
                setLoading(false); // Stop loading after data is fetched
            }
        };

        if (query) {
            fetchSearchResults(); // Fetch search results if there's a query
        }
    }, [query]);


    // Function to handle navigation based on the table
    const handleViewProfile = (result) => {
        console.log("View Profile button clicked!"); // Log when the button is clicked
        console.log("Result data:", result); // Log the result data being passed to the function

        if (result.table === 'Employee') {
            console.log("Navigating to EmployeeViewInfo with id:", result.id);
            navigate(`/EmployeeViewInfo?id=${result.id}`);
        } else if (result.table === 'Security Personnel') {
            console.log("Navigating to ViewPersonnelInfo with id:", result.id);
            navigate(`/ViewPersonnelInfo?id=${result.id}`);
        } else if (result.table === 'Department Head') {
            console.log("Navigating to ViewDepartmentHeadInfo with id:", result.id);
            navigate(`/ViewDepartmentHeadInfo?id=${result.id}`);
        } else {
            console.error("Unknown table:", result.table); // Log an error if the table is unrecognized
        }
    };

    // Function to render a table with the search results
    const renderTable = (data, title) => (
        <>
            {data.length > 0 && (
                <>
                    <h3>{title}</h3>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>

                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((result) => (
                                <tr key={result.id}>
                                    <td>{result.firstname}</td>
                                    <td>{result.lastname}</td>
                                    <td>{result.email}</td>
                                    <td>{result.phone}</td>

                                    <td>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => handleViewProfile(result)}
                                        >
                                            View Profile
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
        </>
    );

    return (
        <div
            className="dashboard-container"
            style={{
                display: 'flex',
                backgroundColor: isDarkMode ? '#333' : 'lightgrey',
                color: isDarkMode ? '#f0f0f0' : '#000',
                minHeight: '100vh',
            }}
        >
            <Sidebar /> {/* Sidebar Component */}

            <div style={{ flex: 1 }}>
                <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} /> {/* Header Component */}

                <main
                    className="main-content"
                    style={{
                        backgroundColor: isDarkMode ? '#444' : 'white',
                        padding: '20px',
                        borderRadius: '8px',
                        margin: '20px',
                        color: isDarkMode ? '#f0f0f0' : '#000',
                    }}
                >
                    <h1>Search Results for "{query}"</h1>

                    {loading ? (
                        <p>Loading results...</p> // Display loading text while fetching data
                    ) : (
                        <>
                            {renderTable(employeeResults, 'Employee')} {/* Conditionally render Employee Table */}
                            {renderTable(securityPersonnelResults, 'Security Personnel')} {/* Conditionally render Security Personnel Table */}
                            {renderTable(departmentHeadResults, 'Department Head')} {/* Conditionally render Department Head Table */}
                        </>
                    )}
                </main>
            </div>
        </div>
    );
};

export default SearchResult;
