import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Table, Button, Modal } from 'react-bootstrap';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';

const ManageEmployee = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const navigate = useNavigate(); // Hook for navigation

  // Function to fetch employee data
  const fetchAndSetEmployees = async () => {
    console.log('Fetching employee data...');
    try {
      const { data, error } = await supabase.from('employee').select('*');
      if (error) {
        console.error('Error fetching employees:', error);
        return;
      }
      console.log('Fetched data:', data);
      if (Array.isArray(data)) {
        setEmployees(data);
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };
  const handleViewInfo = (employee) => {

    navigate(`/EmployeeViewInfo?id=${employee.id}`); // Navigate to EmployeeViewInfo page with ID query parameter
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || user.role !== 'admin') {
      navigate('/');
    }
    fetchAndSetEmployees();

    // Polling every 5 seconds
    const intervalId = setInterval(() => {
      fetchAndSetEmployees();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleShowDeleteModal = (employee) => {
    setSelectedEmployee(employee);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedEmployee(null);
  };

  const handleDeleteEmployee = async () => {
    if (!selectedEmployee) return;

    const { error } = await supabase
      .from('employee')
      .delete()
      .eq('id', selectedEmployee.id);

    if (error) {
      console.error('Error deleting employee:', error);
    } else {
      fetchAndSetEmployees(); // Refresh employee list after deletion
      handleCloseDeleteModal();
    }
  };

  return (
    <div className="dashboard-container" style={{ display: 'flex', minHeight: '100vh' }}>
      <Sidebar />

      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

        <main
          className="main-content"
          style={{
            overflowY: 'auto',
            height: 'calc(100vh - 80px)', // Adjust height to account for header
            backgroundColor: isDarkMode ? '#444' : 'white',
            padding: '20px',
            borderRadius: '8px',
            margin: '20px',
            color: isDarkMode ? '#f0f0f0' : '#000',
          }}
        >
          <h1>Manage Employees</h1>
          <Table
            striped
            bordered
            hover
            variant={isDarkMode ? 'dark' : 'light'}
            style={{
              backgroundColor: isDarkMode ? '#555' : '#fff',
              color: isDarkMode ? '#f0f0f0' : '#000',
            }}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Department</th>
                <th>Contact</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {employees.map(employee => (
                <tr key={employee.id}>
                  <td>{employee.firstname} {employee.lastname}</td>
                  <td>{employee.email}</td>
                 
                  <td>{employee.department}</td>
                  <td>{employee.phone}</td>
                  <td>
                  <Button 
                          variant="success" 
                          size="sm" 
                          className="me-2" 
                          onClick={() => handleViewInfo(employee)}
                        >
                          View Info
                        </Button>
                    <Button variant="danger" onClick={() => handleShowDeleteModal(employee)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Delete Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete {selectedEmployee?.firstname} {selectedEmployee?.lastname}?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
              <Button variant="danger" onClick={handleDeleteEmployee}>Delete</Button>
            </Modal.Footer>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default ManageEmployee;
