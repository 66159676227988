// Updated OTPVerification.js
import React, { useState } from 'react';
import { Container, Form, Button, Alert, Card } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { supabase } from '../supabaseClient';

const OTPVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email; // Get the email passed from ForgotPassword
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleVerify = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Fetch the OTP record from the database
    const { data, error: otpError } = await supabase
      .from('otp_codes')
      .select('*')
      .eq('email', email)
      .eq('otp_code', otp)
      .gt('expires_at', new Date().toISOString()) // Check if the OTP is still valid
      .single();

    if (otpError || !data) {
      setError('Invalid or expired OTP.');
      return;
    }

    setSuccess('OTP verified successfully! Redirecting to change your password...');
    setTimeout(() => {
      navigate('/change-password', { state: { email } }); // Redirect to the Change Password page
    }, 2000);
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Card className="shadow-lg p-4" style={{ maxWidth: '400px', width: '100%' }}>
        <Card.Header className="text-center bg-primary text-white">
          <h4>Verify OTP</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleVerify}>
            <Form.Group className="mb-4" controlId="formBasicOtp">
              <Form.Label>Enter OTP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the OTP sent to your email"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                isInvalid={!!error}
              />
              <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </Form.Group>

            {success && <Alert variant="success">{success}</Alert>}

            <Button variant="primary" type="submit" className="w-100">
              Verify OTP
            </Button>
          </Form>
        </Card.Body>
        <Card.Footer className="text-center">
          <small className="text-muted">Enter the OTP sent to your email.</small>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default OTPVerification;
