import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';

const EmployeeViewInfo = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [employee, setEmployee] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const employeeId = queryParams.get('id');

  const navigate = useNavigate();

  const fetchEmployeeById = async (id) => {
    try {
      const { data, error } = await supabase
        .from('employee')
        .select('*')
        .eq('id', id)
        .single();
      if (error) {
        console.error('Error fetching employee:', error);
        return;
      }
      setEmployee(data);
    } catch (error) {
      console.error('Error fetching employee:', error);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || user.role !== 'admin') {
      navigate('/');
    }
    if (employeeId) {
      fetchEmployeeById(employeeId);
    }
  }, [employeeId]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleExit = () => {
    navigate('/ManageEmployee');
  };

  const exampleEmployee = {
    id: 1,
    firstname: "Jane",
    lastname: "Smith",
    email: "jane.smith@example.com",
    phone: "987-654-3210",
    department: "Finance",
    profile_picture: "https://example.com/path/to/profile_picture.jpg" // Replace with actual URL
  };

  const displayEmployee = employee || (employeeId === "1" ? exampleEmployee : null);

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: isDarkMode ? '#333' : 'lightgrey',
        color: isDarkMode ? '#f0f0f0' : '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

        <main
          className="main-content"
          style={{
            backgroundColor: isDarkMode ? '#444' : 'white',
            padding: '40px',
            borderRadius: '8px',
            margin: '20px',
            color: isDarkMode ? '#f0f0f0' : '#000',
            flex: 1,
            overflowY: 'auto', // Enable vertical scrolling
            maxHeight: 'calc(100vh - 100px)', // Adjust height to account for header and any additional padding
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6}>
                <h1 className="mb-4">Employee Profile</h1>
                {displayEmployee ? (
                  <Card className={`shadow-sm rounded ${isDarkMode ? 'bg-dark text-light' : 'bg-light'}`}>
                    <Card.Img
                      variant="top"
                      src={displayEmployee.profile_picture}
                      alt="Profile Picture"
                      style={{ borderRadius: '50%', width: '200px', height: '200px', objectFit: 'cover', margin: 'auto' }}
                      className="mt-4 mb-3"
                    />
                    <Card.Body>
                      <Card.Title>{displayEmployee.firstname} {displayEmployee.lastname}</Card.Title>
                      <Card.Text>
                        <strong>Email:</strong> {displayEmployee.email}<br />
                        <strong>Phone:</strong> {displayEmployee.phone}<br />
                        <strong>Department:</strong> {displayEmployee.department}<br />
                      </Card.Text>
                      <Button variant={isDarkMode ? 'outline-light' : 'outline-dark'} className="mt-3" onClick={handleExit}>
                         Back
                      </Button>
                    </Card.Body>
                  </Card>
                ) : (
                  <p>Loading employee data...</p>
                )}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
};

export default EmployeeViewInfo;
