// Updated ForgotPassword.js
import React, { useState } from 'react';
import { Container, Form, Button, Alert, Card, Spinner, InputGroup } from 'react-bootstrap';
import { EnvelopeFill } from 'react-bootstrap-icons';
import emailjs from 'emailjs-com';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const generateOTP = () => Math.floor(100000 + Math.random() * 900000).toString();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const { data: adminData, error: adminError } = await supabase
      .from('admin')
      .select('*')
      .eq('email', email);
      
    const { data: deptHeadData, error: deptHeadError } = await supabase
      .from('department_head')
      .select('*')
      .eq('email', email);

    if (adminError || deptHeadError || (!adminData.length && !deptHeadData.length)) {
      setError('Email not found in our records.');
      setLoading(false);
      return;
    }

    const otpCode = generateOTP();
    const expiresAt = new Date(new Date().getTime() + 10 * 60000).toISOString(); 

    const { error: otpError } = await supabase.from('otp_codes').insert([
      { email, otp_code: otpCode, expires_at: expiresAt }
    ]);

    if (otpError) {
      setError('Failed to generate OTP. Please try again.');
      setLoading(false);
      return;
    }

    try {
      await emailjs.send(
        'service_crla1iv',  
        'template_uv54t4o', 
        {
          to_email: email,                   
          reply_to: 'noreply@yourapp.com',   
          message: `Your OTP code is: ${otpCode}`, 
        },
        'LtbIUA7B49TOggb24' 
      );

      setSuccess('A verification code has been sent to your email address.');
      setEmailSent(true);

      // Redirect to OTP Verification page with email as state
      navigate('/otp-verification', { state: { email } }); // Changed to state
    } catch (emailError) {
      setError('Failed to send verification email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Card className="shadow-lg p-4" style={{ maxWidth: '400px', width: '100%' }}>
        <Card.Header className="text-center bg-primary text-white">
          <h4>Forgot Password</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <EnvelopeFill />
                </InputGroup.Text>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  isInvalid={!!error}
                />
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            {success && <Alert variant="success">{success}</Alert>}

            <Button variant="primary" type="submit" className="w-100" disabled={loading || emailSent}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Send Verification Code'}
            </Button>
          </Form>
        </Card.Body>
        <Card.Footer className="text-center">
          <small className="text-muted">
            Enter your email address to receive a verification code.
          </small>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default ForgotPassword;
