import React, { useState } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

const Sidebar = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isManageOpen, setIsManageOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('user');
    console.log('Logged out and cleared local storage');
    navigate('/');
    setShowLogoutModal(false);
  };

  const toggleManageDropdown = () => {
    setIsManageOpen(!isManageOpen);
  };

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
      <CDBSidebar textColor="#fff" backgroundColor="#222">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/admin-dashboard" className="text-decoration-none" style={{ color: 'inherit' }}>
            Pass Slip System
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/admin-dashboard" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/SlipRequests" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="table">Slip Requests</CDBSidebarMenuItem>
            </NavLink>
            <div>
              <div onClick={toggleManageDropdown} style={{ cursor: 'pointer' }}>
                <CDBSidebarMenuItem icon="user">
                  Manage {isManageOpen ? <i className="fa fa-caret-up"></i> : <i className="fa fa-caret-down"></i>}
                </CDBSidebarMenuItem>
              </div>
              {isManageOpen && (
                <div style={{ marginLeft: '20px' }}>
                  <NavLink exact to="/ManageSecurityPersonnel" activeClassName="activeClicked">
                    <CDBSidebarMenuItem icon="shield-alt">Manage Security Personnel</CDBSidebarMenuItem>
                  </NavLink>
                  <NavLink exact to="/ManageDepartmentHead" activeClassName="activeClicked">
                    <CDBSidebarMenuItem icon="user-tie">Manage Department Head</CDBSidebarMenuItem>
                  </NavLink>
                  <NavLink exact to="/ManageEmployee" activeClassName="activeClicked">
                    <CDBSidebarMenuItem icon="users">Manage Employee</CDBSidebarMenuItem>
                  </NavLink>
                </div>
              )}
            </div>

            {/* New "Manual Slip Input" functionality */}
            <NavLink exact to="/ManualSlipInput" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="edit">Manual Slip Input</CDBSidebarMenuItem>
            </NavLink>

            <NavLink exact to="/UserVerification" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user-check">User Verification</CDBSidebarMenuItem>
            </NavLink>
            
            <NavLink exact to="/History" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="exclamation-circle">History</CDBSidebarMenuItem>
            </NavLink>

            {/* Statistics Navigation Button */}
            <NavLink exact to="/Statistics" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-bar">Statistics</CDBSidebarMenuItem>
            </NavLink>

          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <Button
            variant="danger"
            onClick={() => setShowLogoutModal(true)}
            style={{ width: '100%', padding: '10px 0' }}
          >
            Logout
          </Button>
        </CDBSidebarFooter>
      </CDBSidebar>

      {/* Enhanced Confirm Logout Modal */}
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
        dialogClassName="modal-90w"
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <i className="fa fa-exclamation-triangle fa-3x text-warning mb-3"></i>
            <h4 className="mb-3">Are you sure you want to logout?</h4>
            <p className="text-muted">You will be redirected to the Login page.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)} className="me-2">
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
